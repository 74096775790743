import React from "react"
import { Layout, Inner, List, FlexCol, Parallax } from "@components/layout"
import { AnimatedLink } from "@components/shared"
import {
  page__wrapper,
  page__header,
  page__content,
} from "./styles/page.module.scss"
import { SingleBenefit } from "@components/standard"
import {
  Breadcrumbs,
  ResizedImage as Image,
  AnimatedSection,
} from "@components/shared"

import {
  parallax,
  parallax__container,
  title,
  gladiators__wave,
  gladiators__wrapper,
  gladiators__header,
  gladiators__title,
  gladiators__content,
  gladiators__section,
  gladiators__section__img,
  gladiators__benefits,
  gladiators__content__important,
  gladiators__btns,
  gladiators__btns__btn,
  gladiators__btns__btn__alt,
} from "./styles/gladiators.module.scss"

import {
  arrows,
  arrows__left,
  arrows__right,
} from "../../components.refactored/theme/DirectionArrows/arrows.module.scss"

const benefits = [
  {
    label: "intensywny trening rzemiosła programistycznego",
    effect: "podniesie twój poziom umiejętności",
  },
  {
    label: "dobre wzorce kodowania",
    effect: "docenisz Ty oraz każdy Twój pracodawca",
  },
  {
    label: "ogrom problemów do rozwiązania",
    effect: "ogrom problemów do rozwiązania",
  },
  {
    label: "udział w projekcie open source",
    effect: "wzbogaci twoje portfolio",
  },
  {
    label: "korekty cv oraz projektów w portfolio",
    effect:
      "wiedza to nie wszystko, nauczymy Cię jak warto zaprezentować swoje repo	",
  },
  {
    label: "zajęcia online z zaawansowanych tematów	",
    effect:
      "żaden z gladiatorów nie walczy samotnie, wsparcie mentora i praca w zespole to podstawa",
  },
]

const Wave = () => {
  return (
    <div className={gladiators__wave}>
      <svg viewBox="0 0 1200 400" width="100%" height="100%">
        <path
          d="M1200,400c-134.35-137.81-310-62.19-769.68-15.42S0,0,0,0V400Z"
          transform="translate(0 0)"
          fill="#fff"
        />
      </svg>
    </div>
  )
}

const Section = ({ title, children, imgStyle, className = "" }) => {
  return (
    <div className={[gladiators__section, className].join(" ")}>
      <AnimatedSection>
        <div className={gladiators__section__img} style={imgStyle}></div>
      </AnimatedSection>
      <h5 className={gladiators__title}>{title}</h5>
      {children}
    </div>
  )
}

// const img =

const Gladiators = ({ pageContext }) => {
  const { page } = pageContext
  return (
    <Layout {...page}>
      <div className={parallax__container}>
        <Parallax height={400} image={page.featuredImage}>
          <h1 className={title}>Trenuję Legion Gladiatorów JavaScriptu</h1>
          <Wave />
        </Parallax>
      </div>
      <Breadcrumbs
        elements={[
          {
            label: page.title,
            url: `/${page.slug}/`,
          },
        ]}
      />
      <div className={gladiators__wrapper}>
        <Section
          className={""}
          title={"O co tu chodzi:"}
          imgStyle={{
            left: "90%",
            bottom: -350,
            backgroundImage: `url(${require("./img/usability.png")})`,
          }}
        >
          <p className={gladiators__content}>
            Ta inicjatywa zrodziła się w mojej głowie na sygnał od społeczności
            młodych programistów, którzy nie mieli swojego miejsca, aby
            poćwiczyć kod pod okiem mentora. Takiego mentora, który da im
            wymagające zadania, zrobi code review ich rozwiązania oraz nie
            będzie owijać w bawełnę jeśli ich kod powienien być lepszy.
          </p>
        </Section>

        <Section
          className={""}
          title={"Czym jest Legion Gladiatorów Javascriptu:"}
        >
          <p className={gladiators__content}>
            Co 2 miesiące, spośród zgłaszających się osób, wybieram grupę
            najzdolniejszych programistów i programistek. Każdy z Gladiatorów
            musi zakodować 2 zadania testowe, aby móc zasilić szergi Legionu.
            Cel Legionu Gladiatorów Javascriptu jest prosty - przez 2 miesiące
            intensywnych treningów pod okiem mentora szybko stać się lepszym
            programistą i nauczyć się pisać testy.
          </p>
        </Section>

        <Section
          className={""}
          title={"Jak wygląda trening z Gladiatorami Javascriptu:"}
          imgStyle={{
            left: "-30%",
            bottom: "-20%",
            backgroundImage: `url(${require("./img/studying.png")})`,
          }}
        >
          <p className={gladiators__content}>
            <div className={gladiators__content__important}>
              <ul>
                <li>
                  Zajęcia z kodowania i code review zaawansowanych zadań z JS,
                  Reacta i testów
                </li>
                <li>Nauka pisania testów intergracyjnych i jednostkowych</li>
                <li>Pomoc w dopracowaniu CV</li>
                <li>Kontakt z mentorem na slacku i discordzie</li>
              </ul>
            </div>
          </p>
        </Section>

        <Section
          className={""}
          title={"Kiedy nadajesz się do Gladiatorów:"}
          imgStyle={{
            left: "60%",
            bottom: "-10%",
            backgroundImage: `url(${require("./img/analytics.png")})`,
          }}
        >
          <p className={gladiators__content}>
            <div className={gladiators__content__important}>
              <ul>
                <li>
                  Zrobisz dobrze 2 technologiczne wyzwania podczas rekrutacji
                </li>
                <li>
                  Przez 2 miesiące trwania Gladiatorów masz zarezerwowane 8h
                  tygodniowo na naukę
                </li>
                <li>
                  Umiesz JS (oraz opcjonalnie Reacta) w stopniu przynajmniej
                  podstawowym
                </li>
                <li>
                  Nie umiesz pisać testów i nie wiesz co to jest lub coś o nich
                  słyszałeś
                </li>
                <li>
                  Jesteś przed pierwszą pracą w IT lub pracujesz jako Junior i
                  chciałbyś nauczyć się testów
                </li>
              </ul>
            </div>
          </p>
        </Section>

        <Section
          className={gladiators__benefits}
          title={"Jakie są korzyści z bycia Gladiatorem:"}
          imgStyle={{
            left: "35%",
            top: "-100%",
            backgroundImage: `url(${require("./img/bar-chart.png")})`,
          }}
        >
          <p className={gladiators__content}>
            {benefits.map((el, k) => {
              return <SingleBenefit {...el} key={k} />
            })}
          </p>
        </Section>

        <Section className={""} title={"Czym jest Live Code Review:"}>
          <p className={gladiators__content}>
            Nie udało się dostać do Gladiatorów? Nic nie szkodzi! Chcesz
            skorzystać i uczyć się razem z nimi? Możesz dopisać się do listy
            mailingowej i dostawać zaproszenia na Live Code Review na Zoomie, na
            którym zadania Gladiatorów są oceniane pod względem dobrego
            podejścia do rozwiązanego problemu oraz jakości i czytelności kodu.
          </p>
        </Section>

        <div>
          <AnimatedSection className={arrows}>
            <AnimatedLink className={arrows__left} to={"/#newsletter"}>
              <div>Chcę uczestniczyć w Live Code Review</div>
            </AnimatedLink>
            <AnimatedLink className={arrows__right} to={"/tasks/"}>
              <div>Chcę kupić zbiory Gladiatorów</div>
            </AnimatedLink>
          </AnimatedSection>
        </div>
      </div>
    </Layout>
  )
}

export default Gladiators
